import React, { useState } from "react";
import video from "../Assets/videos/Record.mp4"
import ChatBOt from "../Assets/videos/ChatBOt.mp4"


const Demos = () => {

  return (
    <div style={{
      display:"flex",
      flexDirection : "column",
      gap : 50
    }}>
      <div >
        <h1 style={{
          fontSize : "45px",
          textAlign:"center"
        }}>
          HR Bot
        </h1>
        <div style={{
            height:600
        }} className=" w-full  ">
            <video style={{
                height:"100%",
                objectFit: 'cover',
                width:"100%"
            }} src={video} controls ></video>
        </div>
      </div>
      <div >
      <h1 style={{
          fontSize : "45px",
          textAlign:"center"
        }}>
          WhatsApp Bot
        </h1>
        <div style={{
            height:600
        }} className=" w-full  ">
            <video style={{
                height:"100%",
                objectFit: '',
                width:"100%"
            }} src={ChatBOt} controls ></video>
        </div>
      </div>
    </div>
  );
};

export default Demos;
